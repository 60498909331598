import { MenuConfig } from '../core/settings/settings';
import { useAuth } from '../auth/use-auth';
import { useSettings } from '../core/settings/use-settings';
import _ from 'lodash';
import { checkRole } from '../utils/common';

export function useCustomMenu(menuOrPosition?: string | MenuConfig) {
  const settings = useSettings();
  const { user, hasPermission } = useAuth();

  if (!menuOrPosition) {
    return null;
  }

  let menu =
    typeof menuOrPosition === 'string'
      ? settings.menus?.find(s => s.positions.includes(menuOrPosition))
      : menuOrPosition;



  if (menu) {
    menu.items = menu.items.filter(item => {
      const hasRoles = (item.roles || []).every(a =>
        user?.roles.find(b => b.id === a)
      );
      const hasPermissions = (item.permissions || []).every(a =>
        hasPermission(a)
      );
      // make sure item has action, otherwise router link will error out
      return item.action && hasRoles && hasPermissions;
    });



    // @ts-ignore
    if (checkRole(user, "admin") && menu.name == "User Dashboard") {

      const obj: MenuConfig = {
        // @ts-ignore
        "type": "route",
        "label": "With draw",
        "order": 16,
        "action": "/dashboard/withdraw-history",
        "icon": [
          {
            "tag": "path",
            "attr": {
              "d": "m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"
            }
          }
        ],
        "id": "16101990"
      }

      for (const item of menu.items) {
        if (item.label == "With draw")
          return menu
      }
      // @ts-ignore
      menu.items.push(obj);

    }
  }
  // @ts-ignore
  if (checkRole(user, "admin") && menu && menu.name == "User Dashboard") {
    const obj = {
      "type": "route",
      "label": "With draw",
      "action": "/dashboard/withdraw-history",
      "order": 16,
      "icon": [
        {
          "tag": "path",
          "attr": {
            "d": "m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"
          }
        }
      ],
      "id": 16101990
    }

    for (const item of menu.items) {
      if (item.label == "With draw")
        return menu
    }
    // @ts-ignore
    menu.items.push(obj);

  }

  return menu;
}
