import { Role } from "../auth/role";
import { User } from "../auth/user";



export function checkRole(user: User, role: 'users' | 'guests' | 'admin' | 'editor' | 'member') {
    if (role == "admin") {
        if (user?.username == "admin")
            return true
    }
    return false
}
