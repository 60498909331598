import React, {forwardRef, Fragment, ReactElement} from 'react';
import {NavLink} from 'react-router-dom';
import clsx from 'clsx';
import {MenuConfig, MenuItemConfig} from '../core/settings/settings';
import {createSvgIconFromTree} from '../icons/create-svg-icon';
import {Orientation} from '../ui/forms/orientation';
import {useCustomMenu} from './use-custom-menu';
import {Trans} from '../i18n/trans';
import {ITEMS_HIDDEN} from "./hidden-item-menu"

type MatchDescendants = undefined | boolean | ((to: string) => boolean);

interface CustomMenuProps {
  className?: string;
  matchDescendants?: MatchDescendants;
  itemClassName?:
    | string
    | ((props: {
        isActive: boolean;
        item: MenuItemConfig;
      }) => string | undefined);
  gap?: string;
  menu?: string | MenuConfig;
  children?: (menuItem: MenuItemConfig) => ReactElement;
  orientation?: Orientation;
  onlyShowIcons?: boolean;
}
const routePublic = [
  {
    "type": "route",
    "label": "With draw",
    "order": 16,
    "action": "/dashboard/withdraw-history",
    "icon": [
      {
        "tag": "path",
        "attr": {
          "d": "m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"
        }
      }
    ],
    "id": "16101990"
  },
]
const routeAdmin = [
  {
    "type": "route",
    "label": "Withdraw Admin",
    "order": 16,
    "action": "/admin/withdraw",
    "icon": [
      {
        "tag": "path",
        "attr": {
          "d": "m22 3-1.67 1.67L18.67 3 17 4.67 15.33 3l-1.66 1.67L12 3l-1.67 1.67L8.67 3 7 4.67 5.33 3 3.67 4.67 2 3v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V3zM11 19H4v-6h7v6zm9 0h-7v-2h7v2zm0-4h-7v-2h7v2zm0-4H4V8h16v3z"
        }
      }
    ],
    "id": "16101990"
  },
]
export function CustomMenu({
  className,
  itemClassName,
  gap = 'gap-30',
  menu: menuOrPosition,
  orientation = 'horizontal',
  children,
  matchDescendants,
  onlyShowIcons,
}: CustomMenuProps) {
  const menu = useCustomMenu(menuOrPosition);
  if (!menu) return null;
  const menuHasHidden =  menu?.items?.filter((link: any) => !ITEMS_HIDDEN.includes(link?.action))
  const routes = routePublic.filter(item => {
    return !menuHasHidden?.find(it => it.label === item.label) && menu.name === "User Dashboard"
  })
  // @ts-ignore
  const menuPublic = menuHasHidden?.concat(routes)
  let menus = []
  if(menu.name === "Admin Sidebar") {
    // @ts-ignore
    menus = menuPublic.concat(routeAdmin)
  }
  else {
    menus = menuPublic.filter(item => {
      return !routeAdmin?.find(it => it.label === item.label)
    })
  }

  return (
    <div
      className={clsx(
        'flex',
        gap,
        orientation === 'vertical' ? 'flex-col items-start' : 'items-center',
        className
      )}
      data-menu-id={menu.id}
    >
      {menus?.map(item => {
        if (children) {
          return children(item);
        }
        return (
          <CustomMenuItem
            onlyShowIcon={onlyShowIcons}
            matchDescendants={matchDescendants}
            className={props => {
              return typeof itemClassName === 'function'
                ? itemClassName({...props, item})
                : itemClassName;
            }}
            key={item.id}
            item={item}
          />
        );
      })}
    </div>
  );
}

interface MenuItemProps extends React.RefAttributes<HTMLAnchorElement> {
  item: MenuItemConfig;
  className?: (props: {isActive: boolean}) => string | undefined;
  matchDescendants?: MatchDescendants;
  onlyShowIcon?: boolean;
  unstyled?: boolean;
}
export const CustomMenuItem = forwardRef<HTMLAnchorElement, MenuItemProps>(
  (
    {item, className, matchDescendants, unstyled, onlyShowIcon, ...linkProps},
    ref
  ) => {
    const label = <Trans message={item.label} />;
    const Icon = item.icon && createSvgIconFromTree(item.icon);
    const content = (
      <Fragment>
        {Icon && <Icon size="sm" />}
        {(!Icon || !onlyShowIcon) && label}
      </Fragment>
    );

    const baseClassName =
      !unstyled &&
      'block whitespace-nowrap flex items-center justify-start gap-10';

    const focusClassNames = !unstyled && 'outline-none focus-visible:ring-2';

    if (item.type === 'link') {
      return (
        <a
          className={clsx(
            baseClassName,
            className?.({isActive: false}),
            focusClassNames
          )}
          href={item.action}
          target={item.target}
          data-menu-item-id={item.id}
          ref={ref}
          {...linkProps}
        >
          {content}
        </a>
      );
    }
    return (
      <NavLink
        end={
          typeof matchDescendants === 'function'
            ? matchDescendants(item.action)
            : matchDescendants
        }
        className={props =>
          clsx(baseClassName, className?.(props), focusClassNames)
        }
        to={item.action}
        target={item.target}
        data-menu-item-id={item.id}
        ref={ref}
        {...linkProps}
      >
        {content}
      </NavLink>
    );
  }
);
